import { Listbox, Transition } from "@headlessui/react";
import React from "react";

const Multiselect = ({
    options,
    selectedOptions,
    setSelectedOptions,
    selectedItems
}) => {
    return (
        <Listbox value={selectedOptions} onChange={setSelectedOptions}>
            {({ open }) => (
                <>
                    <div className="relative mt-1">
                        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring focus:border-blue-300 sm:text-sm">
                            <span className="block truncate">
                                {selectedItems.length === 0
                                    ? "Select Options"
                                    : selectedItems
                                          ?.map((selected) => selected.label)
                                          .join(", ")}
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Listbox.Options
                                static
                                className="absolute z-10 w-full mt-1 overflow-auto bg-white border rounded-md shadow-lg max-h-60 focus:outline-none sm:text-sm"
                            >
                                {options.map((option) => (
                                    <Listbox.Option
                                        key={option.value}
                                        value={option}
                                        className={({ active }) =>
                                            `${
                                                active
                                                    ? "text-white bg-blue-600"
                                                    : "text-gray-900"
                                            } cursor-pointer select-none relative py-2 pl-3 pr-9`
                                        }
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`${
                                                        selected
                                                            ? "font-semibold"
                                                            : "font-normal"
                                                    } block truncate`}
                                                >
                                                    {option.label}
                                                </span>

                                                {selected && (
                                                    <span
                                                        className={`${
                                                            active
                                                                ? "text-white"
                                                                : "text-blue-600"
                                                        } absolute inset-y-0 right-0 flex items-center pr-4`}
                                                    >
                                                        <svg
                                                            className="w-5 h-5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M5.293 7.293a1 1 0 011.414 0L10 11.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};

export default Multiselect;
