import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import UsersTable from "../components/users/table";
import { getListOfUsers } from "../redux/slices/user";

const Users = () => {
    const users = useSelector(getListOfUsers);
    const [searchText, setSearchText] = useState("");

    const searchedUsers = users.filter((entry) =>
        Object.values(entry).some(
            (val) =>
                typeof val === "string" &&
                val.toLowerCase().match(searchText.toLowerCase())
        )
    );

    return (
        <div className="p-5">
            <div className="flex items-center justify-between">
                <div className="relative mt-5 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <FaSearch className="w-5 h-5 text-gray-400" />
                    </div>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        onChange={(e) => setSearchText(e.target.value)}
                        className="block w-full py-2 pl-10 pr-4 border-gray-300 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Search here"
                    />
                </div>
            </div>
            <UsersTable
                users={searchedUsers}
                onEdit={() => {}}
                onDelete={() => {}}
            />
        </div>
    );
};

export default Users;
