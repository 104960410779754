import { createSlice } from "@reduxjs/toolkit";
import { getRandomInt } from "../../utils/general";
import { getRequest, postRequest } from "../../utils/requests";
import { handleAsyncRequest } from "../utils";

// ----------------------------------------------------------------------
const defaultState = {
    isLoading: false,
    error: null,
    cases: [],
    chatMessages: [
        {
            id: getRandomInt(),
            message: "Welcome to Docket AI! How can I assist you",
            time: new Date(),
            user: 1
        }
    ]
};

const slice = createSlice({
    name: "cases",
    initialState: defaultState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setCases(state, action) {
            state.cases = action.payload;
        },

        setNewCase(state, action) {
            state.cases = [...state.cases, action.payload];
        },

        setNewChatMessage(state, action) {
            state.chatMessages.push(action.payload);
        }
    }
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// get cases action
export const fetchCases = () => async (dispatch) => {
    const response = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: "/cases",
        showToast: false
    });
    if (response.error) throw response.error;
    dispatch(actions.setCases(response.body.cases));
    return response;
};

// create case action
export const createCase = (data) => async (dispatch) => {
    const response = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: postRequest,
        endpoint: "/cases",
        payload: data
    });
    if (response.error) throw response.error;
    dispatch(actions.setNewCase(response.body.case));
    return response;
};

// save chat action
export const saveChat = (data) => async (dispatch) => {
    const response = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: postRequest,
        endpoint: "/cases/chat",
        payload: data
    });
    if (response.error) throw response.error;
    return response;
};

// state selector
export const isCasesLoading = (state) => state.cases.isLoading;

export const getCasesErrors = (state) => state.cases.error;

export const getCases = (state) => state.cases.cases;

export const getCaseById = (id) => (state) =>
    state.cases.cases.find((c) => c.id === id);

export const getChatMessages = (state) => state.cases.chatMessages;
