import React from "react";
import { FaPen, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { isUserAdmin } from "../../redux/slices/user";
import { showErrorToast } from "../../utils/toastUtils";

const KanbanCard = ({ data, setAddTask, setEditTask }) => {
    const { name, description, startTime, endTime, type, assignedTo } = data;

    const isAdminUser = useSelector(isUserAdmin);
    return data.type === "new" ? (
        <div
            className="w-64 h-20 p-4 mb-4 bg-white rounded shadow"
            onClick={() => {
                if (isAdminUser) setAddTask(true);
                else showErrorToast("You're not allowed to take this action");
            }}
        >
            <div className="flex items-center justify-between">
                <p className="text-gray-800">New Task</p>
                <button className="text-blue-500 hover:text-blue-700">
                    <FaPlus />
                </button>
            </div>
        </div>
    ) : (
        <div className="w-64 p-4 mb-4 bg-white rounded shadow h-52">
            <div className="flex justify-between">
                <h3 className="mb-2 text-lg font-semibold">{name}</h3>
                {isAdminUser && (
                    <FaPen
                        className="m-1 text-xl cursor-pointer"
                        onClick={() => setEditTask(data)}
                    />
                )}
            </div>
            <p className="mb-4 text-gray-600">{description}</p>
            <div className="flex items-center justify-between mb-4">
                <div className="text-sm text-gray-500">
                    <span className="font-semibold">Start:</span>{" "}
                    {new Date(startTime).toLocaleDateString()}
                </div>
                <div className="text-sm text-gray-500">
                    <span className="font-semibold">End:</span>{" "}
                    {new Date(endTime).toLocaleDateString()}
                </div>
            </div>
            <div className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Type:</span> {type}
            </div>
            <div className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Assigned To:</span>{" "}
                {assignedTo.firstName} {assignedTo.lastName}
            </div>
        </div>
    );
};

export default KanbanCard;
