import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
const UploadFile = () => {
    return (
        <div className="flex h-1/5 flex-col items-center text-white">
            <FaCloudUploadAlt className="size-24" />
            <h1 className="text-lg">Upload anything</h1>
        </div>
    );
};

export default UploadFile;
