import { createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../utils/requests";
import { clearStore, handleAsyncRequest } from "../utils";

// ----------------------------------------------------------------------
const defaultState = {
    isLoading: false,
    error: null,
    currentUser: {},
    listOfUsers: []
};

const slice = createSlice({
    name: "user",
    initialState: defaultState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        },

        setListOfUsers(state, action) {
            state.listOfUsers = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// register user action
export const registerUser =
    ({ firstName, lastName, email, password, role, phoneNumber }) =>
    async (dispatch) => {
        const { error, body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: postRequest,
            endpoint: "/users/register",
            payload: { firstName, lastName, email, password, role, phoneNumber }
        });
        if (error) throw error;
        return body;
    };

// login user action
export const loginUser =
    ({ email, password }) =>
    async (dispatch) => {
        const { error, body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: postRequest,
            endpoint: "/users/login",
            payload: { email, password }
        });
        if (error) throw error;
        await dispatch(actions.setCurrentUser(body));
        return body;
    };

// get list of users
export const fetchUsers = () => async (dispatch) => {
    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: "/users",
        showToast: false
    });
    if (error) throw error;
    await dispatch(actions.setListOfUsers(body));
    return body;
};

export const logoutUser = () => async (dispatch) => {
    dispatch({ type: clearStore.type });
};

// state selector
export const isUserLoading = (state) => state.user.isLoading;

export const getUserErrors = (state) => state.user.error;

export const getCurrentUser = (state) => state.user.currentUser;

export const getIsUserLoggedIn = (state) => !!state.user.currentUser?.id;

export const getListOfUsers = (state) => state.user.listOfUsers;

export const isUserAdmin = (state) => state.user.currentUser.role === "admin";
