import React, { useState } from "react";
import { FaEnvelopeOpenText, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AvailableNumbersTable from "../components/Messages/AvailableNumbersTable";
import GsmMessage from "../components/Messages/GsmMessage";
import MessageSidebar from "../components/Messages/sidebar";
import Loader from "../components/general/Loader";
import {
    buyNumber,
    fetchAvailableNumbers,
    fetchMessageHistory,
    sendMessage
} from "../redux/slices/messages";
import { getCurrentUser } from "../redux/slices/user";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import AddChatForm from "../components/Messages/AddChatForm";

const ChatApp = () => {
    const dispatch = useDispatch();

    const [activeContact, setActiveContact] = useState("");
    const [availableNumbers, setAvailableNumbers] = useState([]);
    const [message, setMessage] = useState("");
    const [isMsgLoading, setIsMsgLoading] = useState(false);
    const [activeMessages, setActiveMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const currentUser = useSelector(getCurrentUser);

    const handleContactClick = async (contactId) => {
        setActiveContact(contactId);
        setIsLoading(true);
        await dispatch(fetchMessageHistory({ to: contactId }))
            .then((messages) => {
                setActiveMessages(
                    messages.length > 0
                        ? messages
                        : [
                              {
                                  body: "Please start chat from here...",
                                  from: "",
                                  to: contactId,
                                  id: 1,
                                  dateSent: "2024-03-17T10:45:57.000Z"
                              }
                          ]
                );

                setIsLoading(false);
            })
            .catch(() => {
                showErrorToast("Message history fetch failed");
            });
    };

    const handleEnableMessagingService = async () => {
        await dispatch(fetchAvailableNumbers())
            .then((availableNumbers) => {
                setAvailableNumbers(availableNumbers);
            })
            .catch((error) =>
                showErrorToast(error.message || "Error fetching numbers")
            );
    };

    const handleBuyNumber = async (number) => {
        await dispatch(buyNumber({ phoneNumber: number.phoneNumber }));
    };

    const handleSendMessage = async () => {
        if (!message) return showErrorToast("Please enter message");
        setIsMsgLoading(true);
        await dispatch(sendMessage({ message, to: activeContact }))
            .then((response) => {
                setActiveMessages([...activeMessages, response]);
                setMessage("");
                setIsMsgLoading(false);
                showSuccessToast("Your message has been sent successfully");
            })
            .catch((error) => {
                showErrorToast(error.message || "Error in sending message");
            });
    };

    return (
        <div>
            {openAdd && (
                <AddChatForm setOpenAdd={setOpenAdd} openAdd={openAdd} />
            )}
            {currentUser.messagingServiceEnabled ? (
                <div className="flex flex-col justify-center h-screen">
                    <button
                        onClick={() => setOpenAdd(!openAdd)}
                        className="inline-flex items-center w-1/5 px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <FaPlus
                            className="w-5 h-5 mr-2 -ml-1"
                            aria-hidden="true"
                        />
                        Add New Contact
                    </button>
                    <div className="flex m-4 bg-white rounded-lg h-2/3">
                        <MessageSidebar
                            handleContactClick={handleContactClick}
                            activeContact={activeContact}
                        />
                        <div className="flex-1 bg-gray-100 rounded-lg">
                            {isLoading ? (
                                <Loader />
                            ) : activeMessages.length > 0 &&
                              activeContact !== "" ? (
                                <div className="h-full p-6 bg-white rounded-lg shadow">
                                    <div className="my-4 overflow-y-scroll h-5/6">
                                        {activeMessages.map((message) => (
                                            <GsmMessage
                                                message={message}
                                                key={message.id}
                                            />
                                        ))}
                                    </div>
                                    <div className="flex">
                                        <input
                                            type="text"
                                            className="w-full p-2 bg-gray-300 border rounded"
                                            placeholder="Message here..."
                                            value={message}
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "Enter" &&
                                                    !isMsgLoading
                                                ) {
                                                    handleSendMessage();
                                                }
                                            }}
                                        />
                                        <button
                                            onClick={handleSendMessage}
                                            className="inline-flex items-center px-4 py-2 mx-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            disabled={isMsgLoading}
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center justify-center h-full p-6 bg-white rounded-lg shadow">
                                    <div className="text-2xl">
                                        Please select any user to send messages
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col justify-center h-screen">
                    {availableNumbers.length > 0 ? (
                        <AvailableNumbersTable
                            phoneNumbers={availableNumbers}
                            handleBuyNumber={handleBuyNumber}
                        />
                    ) : (
                        <div className="pl-5 h-2/4">
                            <button
                                type="button"
                                onClick={handleEnableMessagingService}
                                className="inline-flex items-center px-4 py-2 text-sm font-medium text-black bg-white border border-transparent rounded-md shadow-sm hover:bg-gray-300"
                            >
                                <FaEnvelopeOpenText
                                    className="w-5 h-5 ml-1 mr-2 text-black"
                                    aria-hidden="true"
                                />
                                Enable Messaging Service
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatApp;
