import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    fetchConversationList,
    getConversationList
} from "../../redux/slices/messages";
import { useSelector } from "react-redux";

const Sidebar = ({ handleContactClick, activeContact }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();

    const phoneNumber = useSelector(getConversationList);

    useEffect(() => {
        (async function () {
            await dispatch(fetchConversationList());
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="w-64 text-white bg-[#736496] rounded-l-lg">
            <div className="p-4">
                <div className="flex items-center p-2 bg-[#9e89ce] rounded">
                    <input
                        className="w-full text-white placeholder-gray-300 bg-transparent"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div
                className="overflow-y-auto"
                style={{ height: "calc(100% - 5rem)" }}
            >
                {phoneNumber
                    .filter((item) =>
                        item.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((item) => (
                        <div
                            onClick={() => handleContactClick(item)}
                            key={item}
                            className={`p-4 hover:bg-[#9e89ce] ${
                                activeContact === item && "bg-[#9e89ce]"
                            }`}
                        >
                            {item}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Sidebar;
