import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { registerUser } from "../../redux/slices/user";
import { RegisterSchema } from "../../schemas/authSchema";

const Register = () => {
    const [selectedRole, setSelectedRole] = useState("user");

    const handleOptionChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(RegisterSchema)
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegister = async (data) => {
        await dispatch(registerUser({ ...data, role: selectedRole })).then(
            () => {
                reset();
                navigate("/");
            }
        );
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 app-background">
            <Logo />
            <div className="w-2/5 p-8 bg-white rounded-md shadow-md">
                <h2 className="mb-4 text-2xl font-bold text-center">
                    Register
                </h2>
                <form onSubmit={handleSubmit(handleRegister)}>
                    <div className="mb-4">
                        <label
                            htmlFor="firstName"
                            className="block mb-2 text-gray-600"
                        >
                            First Name:
                        </label>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your first name"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.firstName?.message}
                        </p>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="lastName"
                            className="block mb-2 text-gray-600"
                        >
                            Last Name:
                        </label>
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your last name"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.lastName?.message}
                        </p>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block mb-2 text-gray-600"
                        >
                            Email:
                        </label>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your email"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.email?.message}
                        </p>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="password"
                            className="block mb-2 text-gray-600"
                        >
                            Password:
                        </label>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="password"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your password"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.password?.message}
                        </p>
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="phoneNumber"
                            className="block mb-2 text-gray-600"
                        >
                            Phone Number:
                        </label>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="tel"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your Phone Number"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.phoneNumber?.message}
                        </p>
                    </div>

                    <div className="flex items-center p-4 space-x-4">
                        <label
                            htmlFor="role"
                            className="block mb-2 text-gray-600"
                        >
                            Register as:
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="user"
                                checked={selectedRole === "user"}
                                onChange={handleOptionChange}
                                className="mr-2"
                            />
                            User
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="admin"
                                checked={selectedRole === "admin"}
                                onChange={handleOptionChange}
                                className="mr-2"
                            />
                            Admin
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                    >
                        Register
                    </button>
                </form>
                <div className="mt-4 text-center">
                    <p className="flex justify-center text-sm">
                        <p className="mr-3">Already an account?</p>
                        <p
                            className="text-blue-500 cursor-pointer"
                            onClick={() => navigate("/")}
                        >
                            Login here
                        </p>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
