import { createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../utils/requests";
import { handleAsyncRequest } from "../utils";
import { actions as UserActions } from "./user";
// ----------------------------------------------------------------------
const defaultState = {
    isLoading: false,
    error: null,
    conversationList: []
};

const slice = createSlice({
    name: "messages",
    initialState: defaultState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setConversationList(state, action) {
            state.conversationList = action.payload;
        },

        updateConversationList(state, action) {
            state.conversationList = [
                ...state.conversationList,
                action.payload
            ];
        }
    }
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// get available numbers
export const fetchAvailableNumbers = () => async (dispatch) => {
    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: "/messages/availablePhoneNumber"
    });
    if (error) throw error;
    return body.numbers;
};

// get conversation list
export const fetchConversationList = () => async (dispatch) => {
    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: "/messages/conversationList"
    });
    if (error) throw error;
    dispatch(actions.setConversationList(body.phoneNumbers));
    return body.phoneNumbers;
};

// buy a new phone number
export const buyNumber =
    ({ phoneNumber }) =>
    async (dispatch, getState) => {
        const email = getState().user.currentUser.email;
        const { error, body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: postRequest,
            endpoint: "/messages/buyNumber",
            payload: { phoneNumber, email }
        });
        if (error) throw error;
        dispatch(UserActions.setCurrentUser(body.data));
        return body;
    };

// get message history
export const fetchMessageHistory =
    ({ to }) =>
    async (dispatch, getState) => {
        const twilioPhoneNumber =
            getState().user.currentUser.twilioConfig.twilioPhoneNumber;
        const { error, body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: postRequest,
            endpoint: "/messages/messagesHistory",
            payload: { from: twilioPhoneNumber, to }
        });
        if (error) throw error;
        return body.messages;
    };

// send message
export const sendMessage =
    ({ to, message }) =>
    async (dispatch, getState) => {
        const twilioPhoneNumber =
            getState().user.currentUser.twilioConfig.twilioPhoneNumber;
        const { error, body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: postRequest,
            endpoint: "/messages/sendMessage",
            payload: { from: twilioPhoneNumber, to, message }
        });
        if (error) throw error;
        return body.message;
    };

// state selector
export const isMessagesLoading = (state) => state.messages.isLoading;

export const getMessagesErrors = (state) => state.messages.error;

export const getConversationList = (state) => state.messages.conversationList;
