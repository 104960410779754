let store;
export const injectStore = (_store) => {
    store = _store;
};

export const setupSocketRules = (socket) => {
    if (!socket.on) return;

    socket.on("any event", (data) => {
        console.log("Logger=======>", data, store);
    });
};
