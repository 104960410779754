import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
            <div className="text-4xl font-bold text-gray-800 mb-4">
                Oops! Page Not Found
            </div>
            <p className="text-lg text-gray-600 mb-8">
                The page you are looking for might be in another dimension.
            </p>
            <div className="flex justify-between w-2/5">
                <Link
                    to={-1}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded focus:outline-none focus:ring focus:border-blue-300 hover:bg-blue-600"
                >
                    Go back
                </Link>
                <Link
                    to="/"
                    className="mt-4 bg-green-500 text-white py-2 px-4 rounded focus:outline-none focus:ring focus:border-green-300 hover:bg-green-600"
                >
                    Go to Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
