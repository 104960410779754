import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CaseCard from "../components/caseDetails/CaseCard";
import CaseFileDetails from "../components/caseDetails/CaseFileDetails";
import CaseInfoDetails from "../components/caseDetails/CaseInfoDetail";
import ChatPopup from "../components/caseDetails/ChatPopup";
import { getCaseById } from "../redux/slices/cases";

const CaseDetails = () => {
    const { id } = useParams();
    const [folderPopup, setFolderPopup] = useState(null);
    const [chatPopup, setChatPopup] = useState(false);
    const caseDetails = useSelector(getCaseById(id));
    const [loader] = useState(false);
    const [trainingStatus, setTrainingStatus] = useState({});
    const [filePopup, setFilePopup] = useState(null);

    return (
        <div className="flex h-screen p-5">
            <div className="w-3/5">
                <CaseCard caseDetails={caseDetails} />
                <div className="flex flex-col items-end justify-end h-1/5"></div>
                <CaseFileDetails
                    setFolderPopup={setFolderPopup}
                    setChatPopup={setChatPopup}
                    caseDetails={caseDetails}
                    trainingStatus={trainingStatus}
                    setTrainingStatus={setTrainingStatus}
                    setFilePopup={setFilePopup}
                />
            </div>
            <div className="w-2/5">
                <CaseInfoDetails caseDetails={caseDetails} />
            </div>
            {chatPopup && (
                <ChatPopup
                    setChatPopup={setChatPopup}
                    folderPopup={folderPopup}
                    filePopup={filePopup} // Pass filePopup to ChatPopup
                    loader={loader}
                    caseId={id}
                    trainingStatus={trainingStatus}
                />
            )}
        </div>
    );
};

export default CaseDetails;
