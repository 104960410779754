import React from "react";
import OpenAIChat from "../components/Dashboard/OpenAIChat";
import TodaysCalendar from "../components/Dashboard/TodayCalender";
import UploadFile from "../components/Dashboard/UploadFile";

const Dashboard = () => {
    return (
        <div className="relative bg-transparent">
            <div className="container flex h-screen p-5">
                <div className="w-3/5 mb-5">
                    <UploadFile />
                    <OpenAIChat />
                </div>
                <div className="w-2/5">
                    <div className="h-1/5"></div>
                    <div>
                        <TodaysCalendar />
                        <div className="flex items-center justify-center px-6 py-4">
                            <button
                                type="button"
                                className="w-48 h-12 px-4 py-2 text-sm font-medium text-gray-800 transition duration-300 ease-in-out bg-white rounded-xl hover:bg-gray-500 hover:text-gray-200"
                            >
                                Full Calendar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
