import React from "react";

const CaseCard = ({ caseDetails }) => {
    return (
        <div className="p-4 rounded-lg h-1/5">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold">
                    {caseDetails.caseName}
                </h2>
                <span className="bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                    {caseDetails.statusType}
                </span>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <p className="font-medium">Case No:</p>
                    <p>{caseDetails.caseNo}</p>
                </div>
                <div>
                    <p className="font-medium">Judge:</p>
                    <p>{caseDetails.judge}</p>
                </div>
                <div>
                    <p className="font-medium">Opening Date:</p>
                    <p>
                        {new Date(caseDetails.dateOfOpening).toLocaleDateString(
                            "en-US"
                        )}
                    </p>
                </div>
                <div>
                    <p className="font-medium">Managing Attorney:</p>
                    <p>{caseDetails.managingAttorney}</p>
                </div>
                <div>
                    <p className="font-medium">DOI:</p>
                    <p>
                        {new Date(caseDetails.DOI).toLocaleDateString("en-US")}
                    </p>
                </div>
                <div>
                    <p className="font-medium">SOL:</p>
                    <p>
                        {new Date(caseDetails.SOL).toLocaleDateString("en-US")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CaseCard;
