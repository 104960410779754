// Sidebar.js
import React, { useEffect, useRef, useState } from "react";
import { FaFolder, FaFolderPlus, FaPlus, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { checkModelStatus, trainAIModel } from "../../redux/aiCalls";
import {
    fetchListOfFilesByCase,
    listOfFolders,
    setNewFolder,
    uploadFile
} from "../../redux/slices/files";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";

const CaseFileDetails = ({
    setFolderPopup,
    setChatPopup,
    setFilePopup,
    trainingStatus,
    setTrainingStatus,
    caseDetails
}) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const foldersData = useSelector(listOfFolders);

    const [addFolder, setAddFolder] = useState(false);
    const [folderName, setFolderName] = useState();
    const [expandedFolders, setExpandedFolders] = useState([]);
    const [selectFolderUpload, setSelectFolderUpload] = useState(null);

    useEffect(() => {
        (async function () {
            await dispatch(fetchListOfFilesByCase(caseDetails.id));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileChange = async (e) => {
        const formData = new FormData();
        formData.append("attachments", e.target.files[0]);
        formData.append("caseId", caseDetails.id);
        formData.append("folder", selectFolderUpload);

        await dispatch(uploadFile(formData))
            .then(() => {})
            .catch((error) =>
                showErrorToast(
                    error.message || "Something went wrong, Please try again"
                )
            );
    };

    const handleFolderClick = (folder) => {
        if (expandedFolders.includes(folder)) {
            setExpandedFolders(expandedFolders.filter((f) => f !== folder));
        } else {
            setExpandedFolders([...expandedFolders, folder]);
        }
    };

    const getFileDisplayName = (filePath) => {
        const parts = filePath.split("/");
        return parts[parts.length - 1];
    };

    const onTrainAIModelClick = async (file, folder) => {
        setTrainingStatus((prevStatus) => ({
            ...prevStatus,
            [file]: "training"
        }));

        const decodedFileName = decodeURIComponent(getFileDisplayName(file));

        const payload = {
            case_id: caseDetails.id,
            folder_to_download: folder,
            file_name: decodedFileName
        };
        try {
            const trainingResponse = await trainAIModel(payload);
            if (trainingResponse.status === "200") {
                showSuccessToast(trainingResponse.message);
                checkTrainingStatus(file, folder);
            } else {
                showErrorToast(
                    trainingResponse.message ||
                        "Unexpected response from server"
                );
                setTrainingStatus((prevStatus) => ({
                    ...prevStatus,
                    [file]: "not trained"
                }));
            }
        } catch (error) {
            showErrorToast(
                error.response?.data?.message || "Error training AI model"
            );
            setTrainingStatus((prevStatus) => ({
                ...prevStatus,
                [file]: "not trained"
            }));
        }
    };

    const checkTrainingStatus = async (file, folder) => {
        const decodedFileName = decodeURIComponent(getFileDisplayName(file));

        const statusPayload = {
            case_id: caseDetails.id,
            folder: folder,
            file_name: decodedFileName
        };

        try {
            const statusResponse = await checkModelStatus(statusPayload);
            if (statusResponse.data.status === 201) {
                setTrainingStatus((prevStatus) => ({
                    ...prevStatus,
                    [file]: "trained"
                }));
            } else if (statusResponse.data.status === 202) {
                setTimeout(() => checkTrainingStatus(file, folder), 5000);
            } else {
                showErrorToast("Unexpected response from server.");
                setTrainingStatus((prevStatus) => ({
                    ...prevStatus,
                    [file]: "error"
                }));
            }
        } catch (error) {
            showErrorToast("Error checking model status.");
            setTrainingStatus((prevStatus) => ({
                ...prevStatus,
                [file]: "error"
            }));
        }
    };

    const onEnableChatClick = async (file, folder) => {
        try {
            const decodedFileName = decodeURIComponent(
                getFileDisplayName(file)
            );

            const payload = {
                case_id: caseDetails.id,
                folder: folder, // Assuming folder is available in the scope
                file_name: decodedFileName
            };
            const response = await checkModelStatus(payload);
            if (response.status === 202) {
                showErrorToast(response.data.message);
            } else {
                // Open the chat popup here
                setFolderPopup(folder); // Folder selected for chat
                setChatPopup(true); // Open the ChatPopup component
            }
        } catch (error) {
            showErrorToast(
                error.response?.data?.message || "Error enabling chat"
            );
        }
    };

    return (
        <div className="w-full p-5 bg-white rounded h-3/5">
            <div className="flex items-center justify-between mb-6">
                <h3 className="text-lg font-semibold">NAME</h3>
                <div className="flex">
                    {addFolder && (
                        <div className="flex">
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="block w-full py-2 pl-10 pr-4 border-gray-300 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e) => setFolderName(e.target.value)}
                                placeholder="Add folder name"
                            />
                            <FaPlus
                                className="w-8 text-black-500"
                                onClick={() => {
                                    if (folderName) {
                                        dispatch(setNewFolder(folderName));
                                        setFolderName();
                                        setAddFolder(false);
                                    } else {
                                        showErrorToast(
                                            "Please add folder name"
                                        );
                                    }
                                }}
                            />
                        </div>
                    )}
                    <button>
                        <FaFolderPlus
                            className="w-8 text-black-500"
                            onClick={() => setAddFolder(!addFolder)}
                        />
                    </button>
                </div>
            </div>

            <div className="overflow-y-auto max-h-[80%]">
                <ul>
                    {Object.keys(foldersData).map((folder) => (
                        <li
                            key={folder}
                            className="mb-2 cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleFolderClick(folder);
                            }}
                        >
                            <div className="flex p-2 rounded-md column hover:bg-blue-100">
                                <FaFolder className="w-8 mx-3 text-yellow-500" />
                                <div className="flex items-center justify-between w-full">
                                    <span className="text-sm font-medium">
                                        {folder}
                                    </span>
                                    <div className="flex">
                                        <p
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                fileInputRef.current.click();
                                                setSelectFolderUpload(folder);
                                            }}
                                        >
                                            <FaUpload className="w-5 text-black" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {expandedFolders.includes(folder) && (
                                <ul>
                                    {foldersData[folder].map((file, i) => (
                                        <li
                                            key={file}
                                            className="flex items-center justify-between px-5 py-1 hover:bg-gray-100"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <a
                                                href={file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex-grow"
                                            >
                                                {`${
                                                    i + 1
                                                }: ${getFileDisplayName(file)}`}
                                            </a>
                                            <button
                                                className={`px-2 py-1 ml-2 text-xs text-white ${
                                                    trainingStatus[file] ===
                                                    "training"
                                                        ? "bg-gray-500 cursor-not-allowed"
                                                        : "bg-green-500 hover:bg-green-600"
                                                } rounded`}
                                                onClick={() => {
                                                    if (
                                                        trainingStatus[file] !==
                                                        "training"
                                                    ) {
                                                        onTrainAIModelClick(
                                                            file,
                                                            folder
                                                        );
                                                    }
                                                }}
                                                disabled={
                                                    trainingStatus[file] ===
                                                    "training"
                                                }
                                            >
                                                {trainingStatus[file] ===
                                                "training"
                                                    ? "Training..."
                                                    : "Train AI"}
                                            </button>
                                            {trainingStatus[file] ===
                                                "trained" && (
                                                <button
                                                    className="px-2 py-1 ml-2 text-xs text-white bg-blue-500 rounded hover:bg-blue-600"
                                                    onClick={() => {
                                                        setFilePopup(file);
                                                        onEnableChatClick(
                                                            file,
                                                            folder
                                                        );
                                                    }}
                                                >
                                                    Chat
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <input
                                ref={fileInputRef}
                                type="file"
                                multiple
                                id={folder}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CaseFileDetails;
