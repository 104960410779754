import axios from "axios";
const AI_SERVER_URL = process.env.REACT_APP_AI_SERVER_URL;

export const trainAIModel = async (payload) => {
    try {
        const response = await axios.post(
            `${AI_SERVER_URL}/generate-embedding`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkModelStatus = async (payload) => {
    try {
        const response = await axios.post(
            `${AI_SERVER_URL}/check_embeddings`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const enableChat = async (payload) => {
    try {
        const response = await axios.post(`${AI_SERVER_URL}/chat`, payload, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};
