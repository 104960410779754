import React from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Calender from "./pages/Calender";
import CaseDetails from "./pages/CaseDetails";
import Dashboard from "./pages/Dashboard";
import Emails from "./pages/Emails";
import Intakes from "./pages/Intakes";
import Matters from "./pages/Matters";
import Messages from "./pages/Messages";
import NotFound from "./pages/NotFound";
import Tasks from "./pages/Tasks";
import Users from "./pages/Users";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import { Providers } from "./redux/provider";
import { getIsUserLoggedIn } from "./redux/slices/user";

const AuthRedirectGuard = ({
    children,
    redirectTo,
    redirectIfAuthenticated
}) => {
    const isAuthenticated = useSelector(getIsUserLoggedIn);

    if (isAuthenticated && redirectIfAuthenticated) {
        return <Navigate to={redirectTo} replace />;
    } else if (!isAuthenticated && !redirectIfAuthenticated) {
        return <Navigate to={redirectTo} replace />;
    }

    return children;
};

const App = () => {
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://unpkg.com/react@17/umd/react.development.js";
    document.head.appendChild(scriptElement);

    return (
        <Providers>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <AuthRedirectGuard
                                redirectTo="/dashboard"
                                redirectIfAuthenticated={true}
                            >
                                <Login />
                            </AuthRedirectGuard>
                        }
                    />
                    <Route path="/register" element={<Register />} />
                    <Route
                        path="/dashboard"
                        element={
                            <AuthRedirectGuard
                                redirectTo="/"
                                redirectIfAuthenticated={false}
                            >
                                <Layout />
                            </AuthRedirectGuard>
                        }
                    >
                        <Route index element={<Dashboard />} />
                        <Route path="matters" element={<Matters />} />
                        <Route path="calender" element={<Calender />} />
                        <Route
                            path="caseDetails/:id"
                            element={<CaseDetails />}
                        />
                        <Route path="tasks" element={<Tasks />} />
                        <Route path="emails" element={<Emails />} />
                        <Route path="users" element={<Users />} />
                        <Route path="messages" element={<Messages />} />
                        <Route path="intakes" element={<Intakes />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            <Toaster
                position={"top-right"}
                toastOptions={{ className: "react-hot-toast" }}
            />
        </Providers>
    );
};

export default App;
