import React from "react";
import {
    FaBox,
    FaCalendarAlt,
    FaHome,
    FaRegCheckCircle,
    FaRegCommentAlt,
    FaRegEnvelope,
    FaTasks,
    FaUsers
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/slices/user";
import Logo from "../Logo";

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logoutUser()).then(() => {
            navigate("/");
            localStorage.clear();
        });
    };

    const pathsArray = [
        {
            name: "Dashboard",
            href: "/dashboard",
            icon: <FaHome />
        },
        {
            name: "Users",
            href: "/dashboard/users",
            icon: <FaUsers />
        },
        {
            name: "Matters",
            href: "/dashboard/matters",
            icon: <FaBox />
        },
        {
            name: "Tasks",
            href: "/dashboard/tasks",
            icon: <FaRegCheckCircle />
        },
        {
            name: "Calender",
            href: "/dashboard/calender",
            icon: <FaCalendarAlt />
        },
        {
            name: "Emails",
            href: "/dashboard/emails",
            icon: <FaRegEnvelope />
        },
        {
            name: "Messages",
            href: "/dashboard/messages",
            icon: <FaRegCommentAlt />
        },
        {
            name: "Intakes",
            href: "/dashboard/intakes",
            icon: <FaTasks />
        }
    ];
    return (
        <aside className="fixed w-64 h-screen">
            <div className="flex justify-center px-6 pt-10">
                <Logo />
            </div>
            <div className="px-6 py-10 text-xl font-bold text-center text-gray-1000">
                DOCKET AI
            </div>
            <div className="flex flex-col justify-between bg-[#b5b1d4] rounded-xl">
                <nav>
                    {pathsArray.map((path, i) => (
                        <Link
                            key={i}
                            to={path.href}
                            className="flex items-center py-2 px-6 h-12 overflow-hidden text-lg font-normal text-gray-800 transition duration-300 ease-in-out hover:rounded-xl hover:bg-[linear-gradient(to right, #a18cd1 0%, #fbc2eb 100%)] hover:text-gray-800"
                        >
                            <i>{path.icon}</i>
                            <span className="pl-2 leading-10">{path.name}</span>
                        </Link>
                    ))}
                </nav>
                <div className="flex items-center justify-center px-6 py-4">
                    <button
                        type="button"
                        className="w-24 px-4 py-2 text-sm font-medium text-gray-800 transition duration-300 ease-in-out rounded-full h-9 bg-zinc-200 hover:bg-zinc-500 hover:text-gray-800"
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
