import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { actions as tasksActions, updateTask } from "../../redux/slices/tasks";
import { formatString } from "../../utils/general";
import KanbanCard from "./KanbanCard";

const KanbanView = ({ tasks, setAddTask, setEditTask }) => {
    const data = tasks;
    const dispatch = useDispatch();

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const startColumn = data.columns[source.droppableId];
        const endColumn = data.columns[destination.droppableId];

        if (startColumn === endColumn) {
            const newItems = Array.from(startColumn.items);
            newItems.splice(source.index, 1);
            newItems.splice(
                destination.index,
                0,
                startColumn.items.find((item) => item.id === draggableId)
            );

            const newColumn = {
                ...startColumn,
                items: newItems
            };

            const newData = {
                ...data,
                columns: {
                    ...data.columns,
                    [newColumn.id]: newColumn
                }
            };

            dispatch(tasksActions.setTasksByCase(newData));
        } else {
            const startItems = Array.from(startColumn.items);
            const endItems = Array.from(endColumn.items);

            const [draggedItem] = startItems.splice(source.index, 1);
            endItems.splice(destination.index, 0, draggedItem);

            const newStartColumn = {
                ...startColumn,
                items: startItems
            };

            const newEndColumn = {
                ...endColumn,
                items: endItems
            };

            const newData = {
                ...data,
                columns: {
                    ...data.columns,
                    [newStartColumn.id]: newStartColumn,
                    [newEndColumn.id]: newEndColumn
                }
            };

            dispatch(
                updateTask({
                    payload: {
                        type: destination.droppableId
                    },
                    taskId: draggableId
                })
            ).then(() => {
                dispatch(tasksActions.setTasksByCase(newData));
            });
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex w-full overflow-auto">
                {data.columnOrder.map((columnId) => {
                    const column = data.columns[columnId];
                    return (
                        <div
                            key={columnId}
                            className="m-2 bg-gray-100 border shadow-md rounded-2xl"
                        >
                            <h2 className="mx-4 mt-3 text-lg font-semibold">
                                {formatString(column.title)}
                            </h2>
                            <Droppable key={columnId} droppableId={columnId}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="p-2 bg-gray-100 rounded-2xl"
                                    >
                                        {column.items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="p-4 mb-2 bg-white border rounded-2xl"
                                                        style={{
                                                            userSelect: "none",
                                                            ...provided
                                                                .draggableProps
                                                                .style
                                                        }}
                                                    >
                                                        <KanbanCard
                                                            data={item}
                                                            setAddTask={
                                                                setAddTask
                                                            }
                                                            setEditTask={
                                                                setEditTask
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    );
                })}
            </div>
        </DragDropContext>
    );
};

export default KanbanView;
