import React, { useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import KanbanView from "../components/tasks/KanbanView";
import { TaskForm } from "../components/tasks/TaskForm";
import { getCases } from "../redux/slices/cases";
import { fetchTasksByCaseId, getTasksByCase } from "../redux/slices/tasks";
import { isUserAdmin } from "../redux/slices/user";

const Tasks = () => {
    const isAdminUser = useSelector(isUserAdmin);
    const [addTask, setAddTask] = useState(false);
    const [selectedCase, setSelectedCase] = useState("");
    const [editTask, setEditTask] = useState({});
    const handleAddTask = () => {
        setAddTask(!addTask);
        setEditTask({});
    };

    const dispatch = useDispatch();
    const listOfCases = useSelector(getCases);
    const cases = listOfCases.map((caseItem) => ({
        ...caseItem,
        value: caseItem.id,
        label: caseItem.caseName
    }));

    const tasks = useSelector(getTasksByCase);

    const handleChangeCase = async (e) => {
        await dispatch(fetchTasksByCaseId({ caseId: e.target.value }));
        setSelectedCase(e.target.value);
    };

    return (
        <div className="flex flex-col items-center justify-center p-8">
            <div className="flex justify-between w-full">
                <select
                    className="w-1/4 p-2 mt-1 border rounded-md"
                    onChange={handleChangeCase}
                >
                    {[{ value: "", label: "Select any case" }, ...cases].map(
                        (option) => (
                            <option key={option.id} value={option.id}>
                                {option.label}
                            </option>
                        )
                    )}
                </select>
                {isAdminUser && (
                    <button
                        className="flex px-4 py-2 text-3xl font-bold text-white"
                        onClick={handleAddTask}
                    >
                        <FaPlusCircle className="p-1 text-4xl font-bold" />
                        <p>New Task</p>
                    </button>
                )}
            </div>
            <KanbanView
                tasks={tasks}
                setAddTask={setAddTask}
                setEditTask={setEditTask}
            />
            {addTask || Object.keys(editTask).length > 0 ? (
                <TaskForm
                    handleAddTask={handleAddTask}
                    selectedCase={selectedCase}
                    listOfCases={cases}
                    editTask={editTask}
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default Tasks;
