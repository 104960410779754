import * as yup from "yup";

export const newCaseSchema = yup.object().shape({
    caseName: yup.string().required("Case Name is required"),
    caseNo: yup.string().required("Case No is required"),
    SOL: yup.date().required("SOL is required"),
    DOI: yup.date().required("Date of Opening is required"),
    status: yup.string().required("Status is required"),
    statusType: yup.string().required("Status Type is required"),
    daysOpen: yup.number().required("Days Open is required"),
    type: yup.string().required("Type is required"),
    judge: yup.string().required("Judge is required"),
    managingAttorney: yup.string().required("Managing Attorney is required"),
    dateOfOpening: yup.date().required("Date of Opening is required"),
    clientInfo: yup.object().shape({
        name: yup.string().required("Client Name is required"),
        phoneNumber: yup.string().required("Client Phone Number is required"),
        email: yup
            .string()
            .required("Client Email is required")
            .email("Invalid email format"),
        address: yup.string().required("Client Address is required")
    }),
    opposingCounselInfo: yup.object().shape({
        organization: yup
            .string()
            .required("Opposing Counsel Organization Name is required"),
        name: yup.string().required("Opposing Counsel Name is required"),
        phoneNumber: yup
            .string()
            .required("Opposing Counsel Phone Number is required"),
        email: yup
            .string()
            .required("Opposing Counsel Email is required")
            .email("Invalid email format"),
        address: yup.string().required("Opposing Counsel Address is required")
    })
});
