import React, { useEffect, useState } from "react";
import { FaBell, FaPlus, FaRocketchat, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CaseDataTable from "../components/matters/CaseDataTable";
import NewCaseForm from "../components/matters/NewCaseForm";
import { fetchCases, getCases } from "../redux/slices/cases";
import { isUserAdmin } from "../redux/slices/user";

const Matters = () => {
    const isAdminUser = useSelector(isUserAdmin);
    const dispatch = useDispatch();
    const casesData = useSelector(getCases);
    const [showNewCaseForm, setShowNewCaseForm] = useState(false);
    const [statusType, setStatusType] = useState("Active");
    const [searchText, setSearchText] = useState("");

    const navigate = useNavigate();

    const handleCloseCaseModel = () => {
        setShowNewCaseForm(!showNewCaseForm);
    };

    useEffect(() => {
        (async function () {
            await dispatch(fetchCases());
        })();
    }, [dispatch]);

    const filteredCases = casesData.filter(
        (singleCase) => singleCase.statusType === statusType
    );

    const searchedCases = filteredCases.filter((entry) =>
        Object.values(entry).some(
            (val) =>
                typeof val === "string" &&
                val.toLowerCase().match(searchText.toLowerCase())
        )
    );

    return (
        <div className="relative bg-transparent">
            <div className="container h-screen p-4 mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-700">
                            Active Projects
                        </h1>
                        <div className="relative mt-5 mb-5 rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <FaSearch className="w-5 h-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                name="search"
                                id="search"
                                onChange={(e) => setSearchText(e.target.value)}
                                className="block w-full py-2 pl-10 pr-4 border-gray-300 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Search here"
                            />
                        </div>

                        <div className="flex gap-2">
                            <ul className="flex flex-wrap text-sm font-medium text-center ">
                                <li
                                    className="cursor-pointer me-2"
                                    onClick={() => setStatusType("Active")}
                                >
                                    <p
                                        className={`flex items-center justify-center p-4 text-black-600 rounded-t-lg h-2 ${
                                            statusType === "Active"
                                                ? "bg-gray-100"
                                                : "bg-gray-600"
                                        }`}
                                    >
                                        Active
                                    </p>
                                </li>
                                <li
                                    className="cursor-pointer me-2"
                                    onClick={() => setStatusType("Archive")}
                                >
                                    <p
                                        className={`flex items-center justify-center p-4 text-black-600 rounded-t-lg h-2 ${
                                            statusType === "Archive"
                                                ? "bg-gray-100"
                                                : "bg-gray-600"
                                        }`}
                                    >
                                        {" "}
                                        Archive
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {isAdminUser && (
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex">
                                <FaRocketchat className="w-8 text-gray-500" />
                                <FaBell className="w-8 text-gray-500" />
                            </div>
                            <span className="m-3 sm:ml-3">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setShowNewCaseForm(true)}
                                >
                                    <FaPlus
                                        className="w-5 h-5 mr-2 -ml-1"
                                        aria-hidden="true"
                                    />
                                    New Case
                                </button>
                            </span>
                        </div>
                    )}
                </div>
                {showNewCaseForm && (
                    <NewCaseForm
                        onModelClose={handleCloseCaseModel}
                        setShowNewCaseForm={setShowNewCaseForm}
                    />
                )}

                <div className="grid grid-cols-1 gap-4 overflow-y-auto">
                    {searchedCases.map((caseData) => (
                        <CaseDataTable
                            key={caseData.id}
                            caseData={caseData}
                            onRowClick={() => {
                                navigate(
                                    `/dashboard/caseDetails/${caseData.id}`
                                );
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Matters;
