import React from "react";

const TodaysCalendar = () => {
    const eventsData = [
        "Johnson Discovery Due Tomorrow",
        "Draft MSJ For Olvera",
        "Call Potential Call - Aaron Martin",
        "Answer tp Edwards Complain",
        "Dinner With the Lew Clerks"
    ];
    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl font-bold text-white">Today's Calendar</h1>
            {eventsData.map((event, i) => (
                <div
                    className="w-4/5 pt-8 m-2 text-2xl text-center text-white border-t-4"
                    key={i}
                >
                    {event}
                </div>
            ))}
        </div>
    );
};

export default TodaysCalendar;
