import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const PhoneNumberTable = ({ phoneNumbers, handleBuyNumber }) => {
    return (
        <div className="overflow-x-auto">
            <h1 className="bg-white p-4 m-2 rounded text-lg text-red-500 font-bold">
                This numbers is show just once please select any one if you want
                to see more please refresh page
            </h1>
            <table className="min-w-full overflow-hidden bg-white border border-gray-300 rounded-lg">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 border-b text-start">
                            Friendly Name
                        </th>
                        <th className="px-4 py-2 border-b text-start">
                            Phone Number
                        </th>
                        <th className="px-4 py-2 border-b text-start">
                            Country
                        </th>
                        <th className="px-4 py-2 border-b text-start">
                            Region
                        </th>
                        <th className="px-4 py-2 border-b text-start">
                            Capabilities
                        </th>
                        <th className="px-4 py-2 border-b text-start">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {phoneNumbers.map((phoneNumber) => (
                        <tr key={phoneNumber.friendlyName}>
                            <td className="border px-4 py-2">
                                {phoneNumber.friendlyName}
                            </td>
                            <td className="border px-4 py-2">
                                {phoneNumber.phoneNumber}
                            </td>
                            <td className="border px-4 py-2">
                                {phoneNumber.country}
                            </td>
                            <td className="border px-4 py-2">
                                {phoneNumber.region}
                            </td>
                            <td className="border px-4 py-2">
                                <div className="flex">
                                    {phoneNumber.capabilities.voice ? (
                                        <FaCheckCircle className="text-purple-600 mr-2" />
                                    ) : (
                                        <FaTimesCircle className="text-black mr-2" />
                                    )}
                                    {phoneNumber.capabilities.SMS ? (
                                        <FaCheckCircle className="text-purple-600 mr-2" />
                                    ) : (
                                        <FaTimesCircle className="text-black mr-2" />
                                    )}
                                    {phoneNumber.capabilities.MMS ? (
                                        <FaCheckCircle className="text-purple-600 mr-2" />
                                    ) : (
                                        <FaTimesCircle className="text-black mr-2" />
                                    )}
                                </div>
                            </td>
                            <td className="border px-4 py-2">
                                <button
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => handleBuyNumber(phoneNumber)}
                                >
                                    Buy
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PhoneNumberTable;
