import { createSlice } from "@reduxjs/toolkit";

import socketIOClient from "socket.io-client";
import userConstants from "../../constants/userConstants";
const ENDPOINT = process.env.REACT_APP_SERVER_URL;

// ----------------------------------------------------------------------

const initialState = {
    value: null
};

const slice = createSlice({
    name: "socket",
    initialState,
    reducers: {
        setSocket(state, action) {
            const { value } = action.payload;
            state.value = value;
        }
    }
});

// Reducer
export default slice.reducer;
const actions = slice.actions;

// Action Creators
export const setSocket = () => (dispatch, getState) => {
    const state = getState();
    const oldSocket = state.socket.value;
    const token = localStorage.getItem([userConstants.tokenVariable]);

    if (oldSocket && oldSocket.disconnect) {
        oldSocket.disconnect();
    }

    const socket = socketIOClient(ENDPOINT, {
        query: {
            token
        }
    });

    dispatch(
        actions.setSocket({
            value: socket
        })
    );
};

export const getSocket = (state) => state.socket.value;
