import React from "react";

const Calender = () => {
    return (
        <div className="relative bg-transparent">
            <div className="container h-screen p-4 mx-auto">Calendar</div>
        </div>
    );
};
export default Calender;
