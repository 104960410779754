import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/slices/user";

const GsmMessage = ({ message }) => {
    const user = useSelector(getCurrentUser);
    return (
        <div
            key={message.id}
            className={`mb-4 flex flex-col ${
                message.from === user.twilioConfig.twilioPhoneNumber
                    ? "mr-auto items-end"
                    : "ml-auto items-start"
            }`}
        >
            <div
                className={`p-4 rounded-lg text-white text-left ${
                    message.from === user.twilioConfig.twilioPhoneNumber
                        ? "bg-indigo-600"
                        : "bg-gray-600"
                }`}
                style={{ maxWidth: "80%" }}
            >
                <div>
                    <span className="font-bold">{message.from}</span>
                </div>
                <div
                    style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word"
                    }}
                >
                    {message.body}
                </div>
            </div>
            <div
                className={`mt-1 text-xs text-black ${
                    message.from === user.twilioConfig.twilioPhoneNumber
                        ? "text-right"
                        : "text-left"
                }`}
            >
                {new Date(message.dateSent).toLocaleString()}
            </div>
        </div>
    );
};

export default GsmMessage;
