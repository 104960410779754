import userConstants from "../constants/userConstants";
import { showErrorToast } from "./toastUtils";
import axios from "axios";
export const API_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

// Create axios instance
const request = axios.create({
    baseURL: API_SERVER_URL
});

// Add a request interceptor
request.interceptors.request.use(
    function (config) {
        config.headers[userConstants.tokenVariable] = localStorage.getItem(
            userConstants.tokenVariable
        );
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
request.interceptors.response.use(
    (response) => {
        const token = response?.data?.body?.token;
        if (token) localStorage.setItem(userConstants.tokenVariable, token);
        return response;
    },
    (error) => {
        if (error.code === "ERR_NETWORK") return showErrorToast(error.message);

        const { statusCode, message } = error.response?.data || {};

        if (statusCode === 400 && message === "Unauthorized token provided") {
            showErrorToast("Logging out - Invalid token");
            localStorage.removeItem("redux-user");
            return (window.location.href = "/");
        }

        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
        );
    }
);

// Post request Call
export const postRequest = async ({ endpoint, payload }) => {
    const response = await request.post(endpoint, payload);
    return response;
};

// Get request Call
export const getRequest = async ({ endpoint }) => {
    const response = await request.get(endpoint);
    return response;
};

// Put request Call
export const putRequest = async ({ endpoint, payload }) => {
    const response = await request.put(endpoint, payload);
    return response;
};

// Patch request Call
export const patchRequest = async ({ endpoint, payload }) => {
    const response = await request.patch(endpoint, payload);
    return response;
};

// Delete Request Call
export const deleteRequest = async ({ endpoint }) => {
    const response = await request.delete(endpoint);
    return response;
};
