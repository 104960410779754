import React from "react";
import { FaPlus, FaWindowClose } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { actions as MessagesActions } from "../../redux/slices/messages";

const AddChatForm = ({ setOpenAdd, openAdd }) => {
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const number = `+${formData.get("phone").replace(/\s/g, "")}`;
        await dispatch(MessagesActions.updateConversationList(number));
        setOpenAdd(!openAdd);
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="w-2/4 p-6 bg-white border rounded-md shadow-lg">
                <h2 className="mb-4 text-2xl font-bold">Add New Chat</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-3">
                        <input
                            className="block w-full p-3 placeholder-gray-800 bg-gray-400 border-gray-600 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="1 234 567 8910"
                            pattern="[0-9]{1} [0-9]{3} [0-9]{3} [0-9]{4}"
                            required
                        />
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 ml-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
                        >
                            <FaPlus className="w-5 h-5 mr-2" />
                            Add
                        </button>

                        <button
                            onClick={() => setOpenAdd(!openAdd)}
                            className="flex items-center px-4 py-2 ml-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:shadow-outline"
                        >
                            <FaWindowClose className="w-5 h-5 mr-2" />
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddChatForm;
