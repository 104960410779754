import React from "react";

const CaseInfoDetails = ({ caseDetails }) => {
    return (
        <div className="max-w-sm p-6 mx-auto">
            <section>
                <h2 className="text-lg font-semibold text-gray-700">
                    Client Info
                </h2>
                <div className="p-4 bg-white rounded-lg shadow">
                    <p className="font-bold">{caseDetails.clientInfo.name}</p>
                    <p>{caseDetails.clientInfo.phoneNumber}</p>
                    <p>{caseDetails.clientInfo.email}</p>
                    <p>{caseDetails.clientInfo.address}</p>
                </div>
            </section>

            <section className="mt-4">
                <h2 className="text-lg font-semibold text-gray-700">
                    Opposing Counsel
                </h2>
                <div className="p-4 bg-white rounded-lg shadow">
                    <p className="font-bold">
                        {caseDetails.opposingCounselInfo.name}
                    </p>
                    <p>{caseDetails.opposingCounselInfo.organization}</p>
                    <p>{caseDetails.opposingCounselInfo.phoneNumber}</p>
                    <p>{caseDetails.opposingCounselInfo.email}</p>
                    <p>{caseDetails.opposingCounselInfo.address}</p>
                </div>
            </section>

            <section className="mt-4">
                <div className="flex items-center justify-between">
                    <h2 className="text-lg font-semibold text-gray-700">
                        Latest Activity
                    </h2>
                    <a href="/viewMore" className="text-blue-500">
                        View More
                    </a>
                </div>
                <div className="mt-2">
                    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow">
                        <div>
                            <p className="font-bold">Discovery Served</p>
                            <p>20 December 2023 - 11:45</p>
                        </div>
                        <a href="/view" className="text-blue-500">
                            View
                        </a>
                    </div>
                    <div className="flex items-center justify-between p-4 mt-2 bg-white rounded-lg shadow">
                        <div>
                            <p className="font-bold">CMC</p>
                            <p>23 December 2023 - 10:30</p>
                        </div>
                        <a href="view" className="text-blue-500">
                            View
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CaseInfoDetails;
