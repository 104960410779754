// src/components/Layout.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { fetchCases } from "../../redux/slices/cases";
import { getSocket, setSocket } from "../../redux/slices/socket";
import { fetchUsers } from "../../redux/slices/user";
import { setupSocketRules } from "../../utils/socket";
import Sidebar from "./Sidebar";

const Layout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async function () {
            await dispatch(fetchUsers());
            await dispatch(fetchCases());
            dispatch(setSocket());
        })();
    }, [dispatch]);
    const socket = useSelector(getSocket);

    useEffect(() => {
        if (socket) setupSocketRules(socket);
    }, [socket]);

    return (
        <div className="flex h-full app-background">
            <Sidebar />

            <div className="flex-grow ml-64 overflow-auto">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
