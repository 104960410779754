import * as yup from "yup";

const commonSchema = {
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required")
};

export const LoginSchema = yup.object().shape({ ...commonSchema });

export const RegisterSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    ...commonSchema
});
