import { createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest, putRequest } from "../../utils/requests";
import { handleAsyncRequest } from "../utils";

// ----------------------------------------------------------------------
const tasksByCaseInitialState = {
    columns: {
        intake: {
            id: "intake",
            title: "intake",
            items: [
                {
                    id: 1,
                    type: "new"
                }
            ]
        },
        demandOfInsurance: {
            id: "demandOfInsurance",
            title: "demandOfInsurance",
            items: [
                {
                    id: 2,
                    type: "new"
                }
            ]
        },
        finalLawsuit: {
            id: "finalLawsuit",
            title: "finalLawsuit",
            items: [
                {
                    id: 3,
                    type: "new"
                }
            ]
        },
        discovery: {
            id: "discovery",
            title: "discovery",
            items: [
                {
                    id: 4,
                    type: "new"
                }
            ]
        },
        adr: {
            id: "adr",
            title: "adr",
            items: [
                {
                    id: 5,
                    type: "new"
                }
            ]
        }
    },
    columnOrder: [
        "intake",
        "demandOfInsurance",
        "finalLawsuit",
        "discovery",
        "adr"
    ]
};
const defaultState = {
    isLoading: false,
    error: null,
    tasksByCase: tasksByCaseInitialState
};

const slice = createSlice({
    name: "tasks",
    initialState: defaultState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setTasksByCase(state, action) {
            state.tasksByCase = action.payload;
        },

        setNewTask(state, action) {
            state.tasksByCase.columns.intake.items.push(action.payload);
        }
    }
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// get list of tasks by case
export const fetchTasksByCaseId =
    ({ caseId }) =>
    async (dispatch) => {
        const { error, body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: getRequest,
            endpoint: `/tasks/${caseId}`,
            showToast: false
        });
        if (error) throw error;
        await dispatch(actions.setTasksByCase(body.tasks));
        return body;
    };

// create task action
export const createTask = (data) => async (dispatch) => {
    const response = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: postRequest,
        endpoint: "/tasks",
        payload: data
    });
    if (response.error) throw response.error;
    dispatch(actions.setNewTask(response.body.tasks));
    return response.body.tasks;
};

export const updateTask =
    ({ payload, taskId }) =>
    async (dispatch) => {
        const response = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: putRequest,
            endpoint: `/tasks/${taskId}`,
            payload: payload
        });
        if (response.error) throw response.error;
        return response.body;
    };

// state selector
export const isTasksLoading = (state) => state.tasks.isLoading;

export const getTasksErrors = (state) => state.tasks.error;

export const getTasksByCase = (state) => state.tasks.tasksByCase;
