import React, { useEffect, useRef, useState } from "react";
import {
    FaCircle,
    FaMinus,
    FaPaperPlane,
    FaPlus,
    FaTimes
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { enableChat } from "../../redux/aiCalls";
import {
    actions as CasesActions,
    getChatMessages,
    saveChat
} from "../../redux/slices/cases";
import { getRandomInt } from "../../utils/general";
import { showErrorToast } from "../../utils/toastUtils";
import Message from "../Dashboard/MessageUI";
import Loader from "../general/Loader";

const ChatPopup = ({
    setChatPopup,
    folderPopup,
    filePopup,
    loader,
    caseId,
    trainingStatus
}) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const chatMessages = useSelector(getChatMessages);

    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const togglePopup = () => {
        setIsMinimized(!isMinimized);
    };

    const closePopup = () => {
        setChatPopup(false);
    };

    useEffect(() => {
        return () => {
            saveChat(chatMessages);
        };
    }, []);

    const handleSendMessage = async () => {
        if (message && trainingStatus[filePopup] === "trained") {
            setIsLoading(true);
            try {
                // Extract just the file name from the file URL
                const fileName = decodeURIComponent(filePopup.split("/").pop()); // This line is added

                const chatPayload = {
                    case_id: caseId,
                    folder: folderPopup,
                    file_name: fileName, // You need to have this passed in or derived from state
                    question: message
                };
                const chatResponse = await enableChat(chatPayload);
                dispatch(
                    CasesActions.setNewChatMessage({
                        id: getRandomInt(),
                        message: chatResponse?.data?.answer,
                        time: new Date(),
                        user: 1
                    })
                );
            } catch (error) {
                showErrorToast(
                    error.message || "Something went wrong in sending message"
                );
            } finally {
                setIsLoading(false);
                setMessage("");
            }
        } else {
            showErrorToast(
                "Please wait until the AI model is trained, or enter a message."
            );
        }
    };

    return (
        <div className="fixed w-80 bottom-8 right-8">
            <div
                className={`relative p-4 bg-white rounded-md shadow-md ${
                    isMinimized ? "h-20" : "h-96"
                }`}
            >
                <div className="flex items-center justify-between mb-2">
                    <div className="font-bold">
                        Welcome to the Docket AI Chat!
                    </div>
                    <div className="flex">
                        <button onClick={togglePopup} className="mr-2">
                            {isMinimized ? <FaPlus /> : <FaMinus />}
                        </button>
                        <button onClick={closePopup}>
                            <FaTimes className="text-gray-500" />
                        </button>
                    </div>
                </div>
                {loader ? (
                    <Loader />
                ) : (
                    !isMinimized && (
                        <>
                            <div
                                ref={chatContainerRef}
                                style={{
                                    maxHeight: "calc(100% - 80px)",
                                    overflowY: "auto"
                                }}
                                className="flex-1 p-4 overflow-auto"
                            >
                                {chatMessages.map((msg) => (
                                    <Message msg={msg} key={msg.id} />
                                ))}
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 m-5">
                                <div className="flex">
                                    <input
                                        type="text"
                                        placeholder="Type your message..."
                                        className="w-full p-2 border rounded-l-md"
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSendMessage();
                                            }
                                        }}
                                    />
                                    <button
                                        className="p-2 text-white bg-blue-500 rounded-r-md"
                                        onClick={handleSendMessage}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <FaCircle />
                                        ) : (
                                            <FaPaperPlane />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </>
                    )
                )}
            </div>
        </div>
    );
};

export default ChatPopup;
