export function formatString(inputString) {
    let formattedString = inputString.replace(/([a-z])([A-Z])/g, "$1 $2");
    formattedString =
        formattedString.charAt(0).toUpperCase() + formattedString.slice(1);

    return formattedString;
}

export function getRandomInt() {
    const minCeiled = Math.ceil(1);
    const maxFloored = Math.floor(100);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
}
