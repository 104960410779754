import React from "react";
import { Controller } from "react-hook-form";
import { formatString } from "../../utils/general";

const InputSelect = ({ control, fieldName, options, defaultValue = null }) => {
    return (
        <div className="mb-4">
            <label
                htmlFor={fieldName}
                className="block text-sm font-medium text-gray-700"
            >
                {formatString(fieldName)}
            </label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => (
                    <>
                        <select
                            id={fieldName}
                            {...field}
                            className={`w-full p-2 mt-1 border rounded-md ${
                                fieldState.invalid ? "border-red-500" : ""
                            }`}
                            value={defaultValue || null}
                            required
                        >
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {fieldState.error && (
                            <p className="text-sm text-red-500">
                                {fieldState.error.message}
                            </p>
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default InputSelect;
