import React from "react";

const UserTable = ({ users, onEdit, onDelete }) => {
    return (
        <div className="container mx-auto mt-10">
            <div className="overflow-x-auto">
                <table className="min-w-full overflow-hidden bg-white border border-gray-300 rounded-lg">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="px-4 py-2 border-b text-start">
                                First Name
                            </th>
                            <th className="px-4 py-2 border-b text-start">
                                Last Name
                            </th>
                            <th className="px-4 py-2 border-b text-start">
                                Email
                            </th>
                            <th className="px-4 py-2 border-b text-start">
                                Role
                            </th>
                            <th className="px-4 py-2 border-b text-start">
                                Phone Number
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user._id} className="hover:bg-gray-50">
                                <td className="px-4 py-2 border-b">
                                    {user.firstName}
                                </td>
                                <td className="px-4 py-2 border-b">
                                    {user.lastName}
                                </td>
                                <td className="px-4 py-2 border-b">
                                    {user.email}
                                </td>
                                <td className="px-4 py-2 border-b">
                                    {user.role}
                                </td>
                                <td className="px-4 py-2 border-b">
                                    {user.phoneNumber}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserTable;
