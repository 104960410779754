import { createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../utils/requests";
import { handleAsyncRequest } from "../utils";
import { actions as UserActions } from "./user";
// ----------------------------------------------------------------------
const defaultState = {
    isLoading: false,
    error: null,
    listOfMails: {}
};

const slice = createSlice({
    name: "mails",
    initialState: defaultState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setListOfMails(state, action) {
            state.listOfMails = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// generate google url callback
export const generateGoogleURLCallback =
    ({ code }) =>
    async (dispatch, getState) => {
        const userEmail = getState().user.currentUser.email;

        const { body } = await handleAsyncRequest({
            dispatch,
            actions,
            requestFn: postRequest,
            endpoint: "/mails/google/auth/callback",
            payload: { code, userEmail }
        });

        dispatch(UserActions.setCurrentUser(body.data));
        return body;
    };

// generate google url
export const generateGoogleURL = () => async (dispatch) => {
    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: "/mails/google/auth",
        showToast: false
    });
    if (error) throw error;
    window.location.replace(body.data);
    return body;
};

// fetch all emails
export const fetchAllMails = () => async (dispatch, getState) => {
    const userEmail = getState().user.currentUser.email;

    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: `/mails/getAllMails/${userEmail}`,
        showToast: false
    });
    if (error) throw error;
    dispatch(actions.setListOfMails(body.data));
    return body;
};

// fetch email by id
export const fetchEmailById = (id) => async (dispatch, getState) => {
    const userEmail = getState().user.currentUser.email;

    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: `/mails/readMail/${userEmail}/${id}`,
        showToast: false
    });
    if (error) throw error;
    return body.data;
};

// send mail
export const sendMail = (to, subject, text) => async (dispatch, getState) => {
    const userEmail = getState().user.currentUser.email;

    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: postRequest,
        endpoint: "/mails/sendMail",
        payload: { from: userEmail, to, subject, text }
    });
    if (error) throw error;

    return body;
    //some
};

// state selector
export const isEmailsLoading = (state) => state.mails.isLoading;

export const getEmailsErrors = (state) => state.mails.error;

export const getAllEmails = (state) => state.mails.listOfMails;
