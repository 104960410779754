import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPlus, FaWindowClose } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createTask } from "../../redux/slices/tasks";
import { getCurrentUser, getListOfUsers } from "../../redux/slices/user";
import { newTaskSchema } from "../../schemas/tasksSchema";
import InputSelect from "../general/InputSelect";
import InputText from "../general/InputText";

export const TaskForm = ({
    handleAddTask,
    selectedCase,
    listOfCases,
    editTask = {}
}) => {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(newTaskSchema),
        defaultValues: { ...editTask }
    });

    const dispatch = useDispatch();
    const listOfUsers = useSelector(getListOfUsers);
    const currentUser = useSelector(getCurrentUser);

    const users = listOfUsers.map((user) => ({
        ...user,
        value: user.id,
        label: `${user.firstName} ${user.lastName}`
    }));

    const fieldsInputArray = [
        { name: "name", type: "text" },
        { name: "description", type: "text" },
        { name: "startTime", type: "datetime-local" },
        { name: "endTime", type: "datetime-local" }
    ];

    const handleSaveTask = async (data) => {
        const taskData = {
            ...data,
            type: "intake",
            assignee: currentUser.id
        };
        await dispatch(createTask(taskData)).then(() => handleAddTask());
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="w-2/4 p-6 bg-white border rounded-md shadow-lg">
                <h2 className="mb-4 text-2xl font-bold">Add New Task</h2>
                <form onSubmit={handleSubmit(handleSaveTask)}>
                    <div className="grid grid-cols-1 gap-3">
                        {fieldsInputArray.map((field, i) => (
                            <InputText
                                key={i}
                                control={control}
                                fieldName={field.name}
                                fieldLabel={field.name}
                                fieldType={field.type}
                            />
                        ))}
                        <InputSelect
                            control={control}
                            fieldName={"case"}
                            options={[
                                { value: "", label: "Select any case" },
                                ...listOfCases
                            ]}
                            defaultValue={selectedCase}
                        />
                        <InputSelect
                            control={control}
                            fieldName={"assignedTo"}
                            options={[
                                { value: "", label: "Select any user" },
                                ...users
                            ]}
                        />
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 ml-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
                        >
                            <FaPlus className="w-5 h-5 mr-2" />
                            Save
                        </button>

                        <button
                            onClick={handleAddTask}
                            className="flex items-center px-4 py-2 ml-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:shadow-outline"
                        >
                            <FaWindowClose className="w-5 h-5 mr-2" />
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
