import React from "react";

const Message = ({ msg }) => {
    return (
        <div
            key={msg.id}
            className={`mb-4 flex flex-col ${
                msg.user === 1 ? "ml-auto items-start" : "mr-auto items-end"
            }`}
        >
            <div
                className={`p-4 rounded-lg text-white text-left ${
                    msg.user === 1 ? "bg-blue-500" : "bg-gray-600"
                }`}
                style={{ maxWidth: "80%" }}
            >
                <div
                    style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word"
                    }}
                >
                    {msg.message}
                </div>
            </div>
            <div
                className={`mt-1 text-xs text-black ${
                    msg.user === 1 ? "text-left" : "text-right"
                }`}
            >
                {new Date(msg.time).toLocaleString()}
            </div>
        </div>
    );
};

export default Message;
