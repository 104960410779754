import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { loginUser } from "../../redux/slices/user";
import { LoginSchema } from "../../schemas/authSchema";

const Login = () => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(LoginSchema)
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = async (data) => {
        await dispatch(loginUser(data))
            .then(() => {
                reset();
                navigate("/dashboard");
            })
            .catch(() => {});
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 app-background">
            <Logo />
            <div className="p-4 m-4 text-purple-600 rounded-xl bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400">
                <h1 className="p-3 text-4xl font-extrabold text-center shadow-lg">
                    🚀 Welcome to <span className="underline">Docket AI</span>{" "}
                    🌟
                </h1>
                <h2 className="hidden p-3 text-4xl font-extrabold text-center shadow-lg md:block">
                    Where Innovation Meets Intelligence!
                </h2>
            </div>
            <div className="w-4/5 p-8 bg-white rounded-md shadow-md md:w-2/5">
                <h2 className="mb-4 text-2xl font-bold text-center">Login</h2>
                <form onSubmit={handleSubmit(handleLogin)}>
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block mb-2 text-gray-600"
                        >
                            Email:
                        </label>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your email"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.email?.message}
                        </p>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="password"
                            className="block mb-2 text-gray-600"
                        >
                            Password:
                        </label>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="password"
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Enter your password"
                                />
                            )}
                        />
                        <p className="mt-1 text-xs text-red-500">
                            {errors.password?.message}
                        </p>
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                    >
                        Login
                    </button>
                </form>
                <div className="mt-4 text-center">
                    <p className="flex justify-center text-sm">
                        <p className="mr-3">Don't have an account?</p>
                        <p
                            className="text-blue-500 cursor-pointer"
                            onClick={() => navigate("/register")}
                        >
                            Register here
                        </p>
                    </p>
                    <p className="flex justify-center text-sm">
                        <p
                            className="text-blue-500 cursor-pointer"
                            onClick={() => navigate("/forgot-password")}
                        >
                            Forgot Password
                        </p>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
