import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
// slices
import casesReducers from "./slices/cases";
import filesReducers from "./slices/files";
import mailsReducers from "./slices/mails";
import socketReducers from "./slices/socket";
import tasksReducers from "./slices/tasks";
import userReducer from "./slices/user";
import messagesReducer from "./slices/messages";

import { clearStore } from "./utils";

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
    getItem() {
        return Promise.resolve(null);
    },
    setItem(_key, value) {
        return Promise.resolve(value);
    },
    removeItem() {
        return Promise.resolve();
    }
});

const storage =
    typeof window !== "undefined"
        ? createWebStorage("local")
        : createNoopStorage();

const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
    whitelist: []
};

const userPersistConfig = {
    key: "user",
    storage,
    keyPrefix: "redux-"
};

const casesPersistConfig = {
    key: "cases",
    storage,
    keyPrefix: "redux-"
};

const filesPersistConfig = {
    key: "files",
    storage,
    keyPrefix: "redux-"
};

const tasksPersistConfig = {
    key: "tasks",
    storage,
    keyPrefix: "redux-"
};

const mailsPersistConfig = {
    key: "mails",
    storage,
    keyPrefix: "redux-"
};

const socketPersistConfig = {
    key: "socket",
    storage,
    keyPrefix: "redux-"
};

const messagesPersistConfig = {
    key: "messages",
    storage,
    keyPrefix: "redux-"
};

const appReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    cases: persistReducer(casesPersistConfig, casesReducers),
    files: persistReducer(filesPersistConfig, filesReducers),
    tasks: persistReducer(tasksPersistConfig, tasksReducers),
    mails: persistReducer(mailsPersistConfig, mailsReducers),
    socket: persistReducer(socketPersistConfig, socketReducers),
    messages: persistReducer(messagesPersistConfig, messagesReducer)
});

const rootReducer = (state, action) => {
    if (action.type === clearStore.type) {
        storage.removeItem("persist:root");
        storage.removeItem("persist:user");
        storage.removeItem("persist:cases");
        storage.removeItem("persist:files");
        storage.removeItem("persist:tasks");
        storage.removeItem("persist:mails");
        storage.removeItem("persist:socket");
        storage.removeItem("persist:messages");

        localStorage.clear();
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
