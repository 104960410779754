import React from "react";
import { FaLocationArrow, FaPlusCircle } from "react-icons/fa";
import Message from "./MessageUI";

const OpenAIChat = () => {
    const chatMessages = [
        {
            id: 1,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 1
        },
        {
            id: 2,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        },
        {
            id: 3,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 1
        },
        {
            id: 4,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        },
        {
            id: 5,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        },
        {
            id: 6,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        },
        {
            id: 7,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        },
        {
            id: 8,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        },
        {
            id: 9,
            message:
                "Certainly! You can create a simple chat UI in React with Tailwind CSS using the provided chatMessages data. Below is an example of how you can structure your React component for the chat UI",
            time: new Date(),
            user: 2
        }
    ];
    return (
        <div className="flex h-4/5 mt-5 bg-white rounded-[20px]">
            <div className="flex flex-col w-full">
                <div className="flex-1 p-4 overflow-auto">
                    {chatMessages.map((msg) => (
                        <Message msg={msg} key={msg.id} />
                    ))}
                </div>

                <div className="flex items-center p-4 border-t">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        className="w-full p-2 mr-6 transition duration-300 ease-in-out border rounded-lg focus:outline-none focus:border-gray-600"
                    />
                    <div className="flex flex-row gap-4">
                        <FaPlusCircle className="cursor-pointer" />
                        <FaLocationArrow className="cursor-pointer" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenAIChat;
