import React, { useEffect, useState } from "react";
import {
    FaArrowLeft,
    FaBars,
    FaEnvelope,
    FaFileAlt,
    FaGoogle,
    FaMinus,
    FaPaperPlane,
    FaPlus,
    FaTimes
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Loader from "../components/general/Loader";
import {
    fetchAllMails,
    fetchEmailById,
    generateGoogleURL,
    generateGoogleURLCallback,
    getAllEmails,
    sendMail
} from "../redux/slices/mails";
import { getCurrentUser } from "../redux/slices/user";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";

const Sidebar = ({ isOpen, onSelectSection }) => {
    const sections = ["inbox", "sent", "drafts"];

    return (
        <div className={`sidebar ${!isOpen ? "open" : ""}`}>
            {isOpen &&
                sections.map((section) => (
                    <button
                        key={section}
                        className="section-button"
                        onClick={() => onSelectSection(section)}
                    >
                        {section === "inbox" && <FaEnvelope className="icon" />}
                        {section === "sent" && (
                            <FaPaperPlane className="icon" />
                        )}
                        {section === "drafts" && <FaFileAlt className="icon" />}
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                    </button>
                ))}
        </div>
    );
};

const SelectedEmail = ({ setOpenedEmail, openedEmail }) => {
    const dispatch = useDispatch();
    const [selectedMail, setSelectedMail] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (openedEmail) {
            (async function () {
                await dispatch(fetchEmailById(openedEmail))
                    .then((data) => {
                        setSelectedMail(data);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        showErrorToast(
                            "Error in fetching selected email, Please select again"
                        );
                        setOpenedEmail("");
                    });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoading ? (
        <div className="h-full">
            <Loader />
        </div>
    ) : (
        <>
            <FaArrowLeft
                className="text-2xl text-gray-800"
                onClick={() => setOpenedEmail("")}
            />
            <div className="p-4 mx-auto mt-4 bg-white border rounded-md shadow-md">
                <div className="mb-4">
                    <div className="mb-2 text-lg font-bold">
                        {selectedMail?.subject}
                    </div>

                    <div className="mb-4 text-gray-700">
                        <span className="block mb-2 font-bold">
                            From: {selectedMail?.from.name}
                        </span>
                        <span className="block mb-2 font-bold">
                            To: {selectedMail?.to.name}
                        </span>
                        <span className="block font-bold">
                            Date: {selectedMail?.date}
                        </span>
                    </div>

                    <div className="mb-6 text-gray-800">
                        <p>{selectedMail?.message}</p>
                    </div>

                    {selectedMail?.attachments &&
                        selectedMail?.attachments.length > 0 && (
                            <div className="mb-4">
                                <p className="mb-2 font-bold">Attachments:</p>
                                <ul className="pl-4 list-disc">
                                    {selectedMail?.attachments.map(
                                        (attachment, index) => (
                                            <li
                                                key={index}
                                                className="text-blue-600 hover:underline"
                                            >
                                                <a href={attachment} download>
                                                    {attachment}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

const SendMailPopup = ({ isEmailModalOpen, setIsEmailModalOpen }) => {
    const dispatch = useDispatch();
    const [isMinimized, setIsMinimized] = useState(false);

    const [emailTo, setEmailTo] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const togglePopup = () => {
        setIsMinimized(!isMinimized);
    };

    const handleEmailModal = () => {
        setIsEmailModalOpen(!isEmailModalOpen);
    };

    const handleSendEmail = async () => {
        let regEmail =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regEmail.test(emailTo) || emailTo === "") {
            await dispatch(sendMail({ to: emailTo, subject, text: message }))
                .then(() => {
                    showSuccessToast("Email sent successfully");
                    handleEmailModal();
                })
                .catch((error) =>
                    showErrorToast("Error in sending mail: " + error.message)
                );
        } else {
            showErrorToast("Please enter a valid email address");
        }
    };

    return (
        <div className="fixed w-96 bottom-8 right-8">
            <div
                className={`relative p-4 bg-gray-300 rounded-md shadow-md ${
                    isMinimized ? "h-20" : "h-[30rem]"
                }`}
            >
                <div className="flex items-center justify-between mb-2">
                    <div className="font-bold">New Message</div>
                    <div className="flex">
                        <button onClick={togglePopup} className="mr-2">
                            {isMinimized ? <FaPlus /> : <FaMinus />}
                        </button>
                        <button onClick={handleEmailModal}>
                            <FaTimes className="text-gray-500" />
                        </button>
                    </div>
                </div>
                {!isMinimized && (
                    <div className="flex flex-col">
                        <label htmlFor="emailTo">To:</label>
                        <input
                            type="email"
                            id="emailTo"
                            className="p-2 mb-2 border rounded-md"
                            value={emailTo}
                            placeholder="Enter mail"
                            onChange={(e) => setEmailTo(e.target.value)}
                        />

                        <label htmlFor="subject">Subject:</label>
                        <input
                            type="text"
                            id="subject"
                            className="p-2 mb-2 border rounded-md"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />

                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            rows="4"
                            className="p-2 mb-2 border rounded-md h-[200px]"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />

                        <button
                            onClick={handleSendEmail}
                            className="px-4 py-2 text-white bg-blue-500 rounded-md"
                        >
                            Send Email
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const EmailList = ({
    currentSection,
    toggleSidebar,
    isOpen,
    openedEmail,
    setOpenedEmail
}) => {
    const dispatch = useDispatch();
    const [sendMail, setSendMail] = useState(false);
    useEffect(() => {
        (async function () {
            await dispatch(fetchAllMails());
        })();
    }, [dispatch]);

    const emails = useSelector(getAllEmails) || {
        inbox: [],
        sent: [],
        drafts: []
    };

    return (
        <>
            <div className="flex flex-col w-full p-4">
                <div className="flex items-center justify-between p-4 mb-4 bg-white shadow-md">
                    <div className="flex items-center justify-start">
                        <div
                            className="m-4 cursor-pointer"
                            onClick={toggleSidebar}
                        >
                            {isOpen ? (
                                <FaPlus className="text-2xl text-gray-800 transform rotate-45" />
                            ) : (
                                <FaBars className="text-2xl text-gray-800" />
                            )}
                        </div>
                        <h1 className="text-xl font-bold">
                            {currentSection.charAt(0).toUpperCase() +
                                currentSection.slice(1)}
                        </h1>
                    </div>
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setSendMail(true)}
                    >
                        <FaPaperPlane
                            className="w-5 h-5 mr-2 -ml-1"
                            aria-hidden="true"
                        />
                        Compose
                    </button>
                </div>
                <div className="flex flex-col overflow-x-hidden overflow-y-scroll h-4/5">
                    {openedEmail ? (
                        <SelectedEmail
                            openedEmail={openedEmail}
                            setOpenedEmail={setOpenedEmail}
                        />
                    ) : (
                        emails[currentSection]?.map((email) => (
                            <div
                                key={email.id}
                                onClick={() => setOpenedEmail(email.id)}
                                className="p-4 mb-4 transition duration-300 bg-white border rounded-md shadow-md cursor-pointer hover:bg-gray-100"
                            >
                                <h2 className="mb-2 text-lg font-bold text-blue-600 truncate hover:underline">
                                    {email?.snippet}
                                </h2>
                            </div>
                        ))
                    )}
                </div>
            </div>
            {sendMail && (
                <SendMailPopup
                    isEmailModalOpen={sendMail}
                    setIsEmailModalOpen={setSendMail}
                />
            )}
        </>
    );
};

const Dashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState("inbox");
    const [openedEmail, setOpenedEmail] = useState("");
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const currentUser = useSelector(getCurrentUser);
    const code = searchParams.get("code");

    useEffect(() => {
        if (code) {
            (async function () {
                await dispatch(generateGoogleURLCallback({ code }));
            })();
        }
    }, [code, dispatch]);

    const handleSectionChange = (section) => {
        setCurrentSection(section);
        closeSidebar();
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        setOpenedEmail("");
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const handleGenerateGoogleURL = async () => {
        await dispatch(generateGoogleURL());
    };

    return currentUser.googleRefreshToken ? (
        <div className="flex flex-col justify-center h-screen">
            <div className="flex m-4 bg-white rounded-lg h-2/3 width-full">
                <Sidebar
                    isOpen={isSidebarOpen}
                    onSelectSection={handleSectionChange}
                />

                <EmailList
                    currentSection={currentSection}
                    toggleSidebar={toggleSidebar}
                    isOpen={isSidebarOpen}
                    openedEmail={openedEmail}
                    setOpenedEmail={setOpenedEmail}
                />
            </div>
        </div>
    ) : (
        <div className="flex flex-col justify-center h-screen">
            <div className="pl-5 h-2/4">
                <button
                    type="button"
                    onClick={handleGenerateGoogleURL}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-black bg-white border border-transparent rounded-md shadow-sm hover:bg-gray-300"
                >
                    <FaGoogle
                        className="w-5 h-5 mr-2 -ml-1 text-red-600"
                        aria-hidden="true"
                    />
                    Connect Google
                </button>
            </div>
        </div>
    );
};

export default Dashboard;
