import { createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../utils/requests";
import { handleAsyncRequest } from "../utils";

// ----------------------------------------------------------------------
const defaultState = {
    isLoading: false,
    error: null,
    listOfFolders: {}
};

const slice = createSlice({
    name: "files",
    initialState: defaultState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setListOfFolders(state, actions) {
            state.listOfFolders = actions.payload;
        },

        setNewFolder(state, action) {
            state.listOfFolders = {
                ...state.listOfFolders,
                [action.payload]: []
            };
        },

        setFileInFolder(state, action) {
            state.listOfFolders[action.payload.folder].push(
                action.payload.fileUrl
            );
        }
    }
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// upload file action
export const uploadFile = (payload) => async (dispatch) => {
    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: postRequest,
        endpoint: "/files/uploadFile",
        payload
    });
    if (error) throw error;

    dispatch(
        actions.setFileInFolder({
            folder: payload.get("folder"),
            fileUrl: body.urls[0]
        })
    );
    return body;
};

// fetch list of files by case
export const fetchListOfFilesByCase = (caseId) => async (dispatch) => {
    const { error, body } = await handleAsyncRequest({
        dispatch,
        actions,
        requestFn: getRequest,
        endpoint: `/files/listOfFilesByCase/${caseId}`,
        showToast: false
    });
    if (error) throw error;
    dispatch(actions.setListOfFolders(body.files));
    return body;
};

export const setNewFolder = (payload) => (dispatch) => {
    dispatch(actions.setNewFolder(payload));
};

// state selector
export const isFilesLoading = (state) => state.files.isLoading;

export const getFilesErrors = (state) => state.files.error;

export const listOfFolders = (state) => state.files.listOfFolders;
