import React from "react";
import { Controller } from "react-hook-form";
import { formatString } from "../../../utils/general";
import Multiselect from "./multiSelect"; // Import the Multiselect component

const InputSelect = ({
    control,
    fieldName,
    options,
    defaultValue = [],
    updateSelectedItems,
    selectedItems
}) => {
    return (
        <div className="mb-4">
            <label
                htmlFor={fieldName}
                className="block text-sm font-medium text-gray-700"
            >
                {formatString(fieldName)}
            </label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => (
                    <>
                        <Multiselect
                            options={options}
                            selectedOptions={field.value}
                            setSelectedOptions={(selectedOptions) => {
                                field.onChange(selectedOptions);
                                updateSelectedItems(selectedOptions);
                            }}
                            selectedItems={selectedItems}
                        />

                        {fieldState.error && (
                            <p className="text-sm text-red-500">
                                {fieldState.error.message}
                            </p>
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default InputSelect;
