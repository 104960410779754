import { createAction } from "@reduxjs/toolkit";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
export const clearStore = createAction("util/clearStore");

export const handleAsyncRequest = async ({
    dispatch,
    actions,
    showToast = true,
    requestFn,
    endpoint,
    payload
}) => {
    try {
        dispatch(actions.startLoading());
        dispatch(actions.hasError(null));

        const body = { endpoint };

        if (payload) body.payload = payload;

        const response = await requestFn(body);

        if (response.data) {
            if (showToast) showSuccessToast(response.data.message);

            return {
                statusCode: response.data.statusCode,
                message: response.data.message,
                body: response.data.body
            };
        } else {
            // Handle the case where response.data is undefined
            throw new Error("No data received from the request");
        }
    } catch (error) {
        dispatch(actions.hasError(error));

        let message = "Something went wrong";
        let statusCode = error.statusCode;

        if (error.response && error.response.data) {
            message = error.response.data.message || message;
            statusCode = error.response.data.statusCode || statusCode;
        } else if (error.message) {
            message = error.message;
        }

        if (showToast) showErrorToast(message);

        return {
            statusCode,
            message,
            error
        };
    } finally {
        dispatch(actions.stopLoading());
    }
};
