import React from "react";
import { FaClock } from "react-icons/fa";
import logoImg from "../../assets/images/logo.png";

const CaseDataTable = ({ caseData, onRowClick }) => {
    const downloadFiles = caseData.download || [];
    const assignedUsers = caseData.assignedUsers || [];

    return (
        <div className="w-full p-2 bg-white rounded-lg shadow-md">
            <div className="overflow-auto">
                <table className="min-w-full table-fixed">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Case Name
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Case No
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                SOL
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Status
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Days Open
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Type
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Download
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                Assigned Users
                            </th>
                            <th className="w-1/6 px-6 py-3 text-sm font-medium text-left text-gray-800">
                                AI Assistant
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b" onClick={onRowClick}>
                            <td className="w-1/6 px-6 py-4 text-sm font-medium text-teal-400 whitespace-nowrap">
                                {caseData.caseName}
                            </td>
                            <td className="w-1/6 px-6 py-4 text-xs text-gray-500 whitespace-nowrap">
                                {caseData.caseNo}
                            </td>
                            <td className="w-1/6 px-6 py-4 text-xs text-gray-500 whitespace-nowrap">
                                {new Date(caseData.SOL).toLocaleDateString(
                                    "en-US"
                                )}
                            </td>
                            <td className="w-1/6 px-6 py-4 whitespace-nowrap">
                                {caseData.status}
                            </td>
                            <td className="w-1/6 px-6 py-4 text-xs text-gray-500 whitespace-nowrap">
                                <FaClock className="w-4 h-4 mr-2 text-gray-400" />
                                {caseData.daysOpen}
                            </td>
                            <td className="w-1/6 px-6 py-4 text-xs text-gray-500 whitespace-nowrap">
                                {caseData.type}
                            </td>
                            <td className="w-1/6 px-6 py-4 text-xs text-gray-500 whitespace-nowrap">
                                {downloadFiles.map((file, index) => (
                                    <div key={index}>
                                        <a
                                            href={file.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {file.name}
                                        </a>
                                    </div>
                                ))}
                            </td>
                            <td className="flex flex-col w-1/6 px-6 py-4 text-sm text-gray-500">
                                {assignedUsers.map((user, index) => (
                                    <span
                                        key={index}
                                        className="whitespace-nowrap"
                                    >
                                        {user.firstName} {user.lastName}
                                        {index < assignedUsers.length - 1
                                            ? ", "
                                            : ""}
                                    </span>
                                ))}
                            </td>
                            <td className="w-1/6 px-6 py-4 text-xs text-gray-500 whitespace-nowrap">
                                <img src={logoImg} alt="logo" width="50" />;
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CaseDataTable;
