import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
    FaArrowLeft,
    FaArrowRight,
    FaPlus,
    FaWindowClose
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createCase } from "../../redux/slices/cases";
import { getListOfUsers } from "../../redux/slices/user";
import { newCaseSchema } from "../../schemas/caseSchema";
import InputSelect from "../general/InputSelect";
import InputText from "../general/InputText";
import InputMultiSelect from "../general/multiSelect/InputMultiSelect";

const NewCaseForm = ({ onModelClose }) => {
    const [page, setPage] = useState(1);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const dispatch = useDispatch();
    const listOfUsers = useSelector(getListOfUsers);
    const users = listOfUsers.map((user) => ({
        ...user,
        value: user.id,
        label: `${user.firstName} ${user.lastName}`
    }));

    const fieldsInputArray = [
        { name: "caseName", type: "text" },
        { name: "caseNo", type: "text" },
        { name: "daysOpen", type: "number" },
        { name: "SOL", type: "date" },
        { name: "DOI", type: "date" },
        { name: "dateOfOpening", type: "date" },
        { name: "judge", type: "text" },
        { name: "managingAttorney", type: "text" }
    ];

    const fieldsSelectArray = [
        {
            name: "statusType",
            options: [
                { value: null, label: "Select Status Type" },
                { value: "Active", label: "Active" },
                { value: "Archive", label: "Archive" }
            ]
        },
        {
            name: "status",
            options: [
                { value: null, label: "Select Status" },
                { value: "Pre-List", label: "Pre-List" },
                { value: "Litigation", label: "Litigation" },
                { value: "ADR", label: "ADR" }
            ]
        },
        {
            name: "type",
            options: [
                { value: null, label: "Select Type" },
                { value: "Civil", label: "Civil" },
                { value: "PI", label: "PI" },
                { value: "Med-Mal", label: "Med-Mal" }
            ]
        }
    ];

    const clientInfoFields = [
        { name: "name", type: "text" },
        { name: "phoneNumber", type: "text" },
        { name: "email", type: "text" },
        { name: "address", type: "text" }
    ];

    const opposingCounselInfoField = [
        { name: "organization", type: "text" },
        { name: "name", type: "text" },
        { name: "phoneNumber", type: "text" },
        { name: "email", type: "text" },
        { name: "address", type: "text" }
    ];

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(newCaseSchema),
        defaultValues: { assignedUsers: [] }
    });

    const onSubmit = async (data) => {
        const assignedUsers = selectedUsers.map((obj) => obj.id);
        const payload = { ...data, assignedUsers };
        await dispatch(createCase(payload))
            .then(() => {
                onModelClose();
            })
            .catch(() => {});
    };

    const updateSelectedItems = (value) => {
        const itemIndex = selectedUsers.findIndex(
            (item) => item.id === value.id
        );

        if (itemIndex !== -1) {
            const updatedItems = [...selectedUsers];
            updatedItems.splice(itemIndex, 1);
            setSelectedUsers(updatedItems);
        } else {
            setSelectedUsers([...selectedUsers, value]);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="w-4/5 p-6 bg-white border rounded-md shadow-lg">
                <h2 className="mb-4 text-2xl font-bold">Add New Case</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {page === 1 ? (
                        <>
                            <div className="grid grid-cols-3 gap-4">
                                {fieldsInputArray.map((field, i) => (
                                    <InputText
                                        key={i}
                                        control={control}
                                        fieldName={field.name}
                                        fieldLabel={field.name}
                                        fieldType={field.type}
                                    />
                                ))}

                                <InputMultiSelect
                                    control={control}
                                    fieldName="assignedUsers"
                                    options={[
                                        { value: "", label: "Select any user" },
                                        ...users
                                    ]}
                                    updateSelectedItems={updateSelectedItems}
                                    selectedItems={selectedUsers}
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                {fieldsSelectArray.map((field, i) => (
                                    <InputSelect
                                        key={i}
                                        control={control}
                                        fieldName={field.name}
                                        options={field.options}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <h1 className="mb-4 text-xl font-bold">
                                    Client Info
                                </h1>
                                <div className="grid grid-cols-2 gap-2">
                                    {clientInfoFields.map((field, i) => (
                                        <InputText
                                            key={i}
                                            control={control}
                                            fieldName={`clientInfo.${field.name}`}
                                            fieldLabel={field.name}
                                            fieldType={field.type}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <h1 className="mb-4 text-xl font-bold">
                                    Opposing Counsel Info
                                </h1>
                                <div className="grid grid-cols-2 gap-2">
                                    {opposingCounselInfoField.map(
                                        (field, i) => (
                                            <InputText
                                                key={i}
                                                control={control}
                                                fieldName={`opposingCounselInfo.${field.name}`}
                                                fieldLabel={field.name}
                                                fieldType={field.type}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-end mt-4">
                        {page === 1 ? (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPage(2);
                                }}
                                className="flex items-center px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
                            >
                                <FaArrowRight className="w-5 h-5 mr-2" />
                                Next
                            </button>
                        ) : (
                            <>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPage(1);
                                    }}
                                    className="flex items-center px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
                                >
                                    <FaArrowLeft className="w-5 h-5 mr-2" />
                                    Back
                                </button>

                                <button className="flex items-center px-4 py-2 ml-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline">
                                    <FaPlus className="w-5 h-5 mr-2" />
                                    Add New Case
                                </button>
                            </>
                        )}
                        <button
                            onClick={onModelClose}
                            className="flex items-center px-4 py-2 ml-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:shadow-outline"
                        >
                            <FaWindowClose className="w-5 h-5 mr-2" />
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewCaseForm;
