import React from "react";
import { Controller } from "react-hook-form";
import { formatString } from "../../utils/general";

const InputText = ({ control, fieldName, fieldType, fieldLabel }) => {
    const formatValue = (field) => {
        if (field.name.toLowerCase().includes("time")) {
            if (field.value) {
                const dateVal = field.value.split(":");
                return `${dateVal[0]}:${dateVal[1]}`;
            } else return field.value;
        } else {
            return field.value;
        }
    };
    return (
        <div className="mb-4">
            <label
                htmlFor={fieldName}
                className="block text-sm font-medium text-gray-700"
            >
                {formatString(fieldLabel)}
            </label>
            <Controller
                name={fieldName}
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <>
                            <input
                                type={fieldType}
                                id={fieldName}
                                {...field}
                                className={`w-full p-2 mt-1 border rounded-md ${
                                    fieldState.invalid ? "border-red-500" : ""
                                }`}
                                value={formatValue(field)}
                            />
                            {fieldState.error && (
                                <p className="text-sm text-red-500">
                                    {fieldState.error.message}
                                </p>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
};

export default InputText;
