import * as yup from "yup";

export const newTaskSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().notRequired(),
    startTime: yup.date().required("Start time is required"),
    endTime: yup.date().required("End time is required"),
    case: yup.string().required("Please select any case"),
    assignedTo: yup.string().required("Please select any user")
});
